.sago-filter-by .btn-outline-primary {
    padding: 6px 12px;
    margin-right: 10px;
   
}

.sago-filter-by {
    display: flex;
    align-items: center;
}

.sago-typehead {
    .input-group-append {
        .input-group-text {
            border-color: #CED4DA;
            padding: 0.875rem 0.75rem;
            color: #42526E;
            background: white;
            border-left: none;
            border-radius: 0px 4px 4px 0px;
            height: 40px;
        }
    }
}

.sago-typehead {
    .form-control {
        border-right: none;
        border-radius: 4px 0px 0px 4px;
        height: 40px;
    }
}

.sago-datefilter {

    .css-6j8sg4-ValueContainer {
        height: 40px;
        background-color: white;
        border-radius: 4px 0px 0px 4px;
        border: 1px solid #CED4DA;
        border-right: none;
    }

    .css-11wtmwo-IndicatorsContainer {
        height: 40px;
        background-color: white;
        border-radius: 0px 4px 4px 0px;
        border: 1px solid #CED4DA;
        border-left: none;
    }

    .css-os6h5y-control {
        
        background: white;
        border-radius: 8px;
        border: 1px solid #CED4DA;
        height: 40px;
    }
}

.sago-selectfilter {

    .react-select__value-container {
        height: 40px;
        background-color: white;
        border-radius: 4px 0px 0px 4px;
        border: 1px solid #CED4DA;
        border-right: none;
    }

    .react-select__indicators {
        height: 40px;
        background-color: white;
        border-radius: 0px 4px 4px 0px;
        border: 1px solid #CED4DA;
        border-left: none;
    }
}

.sago-details {
    .details-title {
        background: white;
        padding: 14px 16px;
        font-weight: 500;
        font-size: 16px;
    }

    .details-body {
        background: #FAFBFC;
        padding: 16px;
    }

    .details-section {
        padding: 16px 0px;
        border-bottom: 1px solid #EBEEF0;

        section:first-child {
            h6 {
                margin-top: 0px;
            }
        }

        h6 {
            font-size: 14px;
            color: #62728D;
            margin-top: 20px;
        }

        p {
            margin-bottom: 0px;
        }

    }

    .details-overall {
        .border-card {
            background: white;
            padding: 16px;
            text-align: center;
            border-radius: 4px;

        }


        p {
            color: #62728D;
        }
    }

}

.sago-single-tab {
    .mdi {
        font-size: 20px;
        color: #000;
    }

    p {
        font-size: 16px;
        color: black;
        margin-left: 16px;
        font-weight: bold;
    }
}

.sago-payment-details {

    .btn-link {
        text-decoration-color: #62728D;

        p {
            color: #62728D;

        }
    }

    .header-section,
    .body-section {

        section {
            padding-right: 85px;

            h6 {
                font-size: 14px;
                color: #62728D;
            }

            p {
                font-size: 14px;
                color: #2E2E2E;
            }
        }
    }
}

.sago-payment-total {
    p {
        color: #5C9EB8;
    }
}

.sago-purchase-table {
    .btn {
        position: absolute;
        right: 24px;
        background: transparent;
    }

    .nav-tabs {
        background-color: transparent;

        .nav-link {
            background-color: #F4F5F7;
            border-radius: 8px 8px 0px 0px;
            margin-right: 2px;

            &.active {
                border-bottom: none;
            }
        }
    }

    .tab-content {
        background: $white;
        border: none;
        border-radius: 8px;
    }

    .table {
        color: #62728D;

        thead th:last-child,
        tbody td:last-child {
            text-align: right;
            
        }

        tbody td:last-child {
            color: #000;
        }
    }
}

.form-legends {
    color: #62728D;                                 
}

.form-control {
    height: 40px;
}

.cursor-pointer {
    cursor: pointer;
}