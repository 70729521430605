.risk-details{
    a{
        height: 125px;
        p{
           font-size: 18px; 
        }
      
    }
    a.active{
        .card{
            background-color: #d62828;
            color: #fff;
        }
      
    }
} 
.risk-table {
    .tab-content{
        border:0px
    }
}